:root {
    --Linear: linear-gradient(90deg, #2036FF 0%, #C348B0 90%);
}

#root {
    position: relative;
}

body, html {
    scroll-behavior: smooth;
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Poppins", serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    overflow-x: hidden;
    max-width: 100%;
}

a {
    all: unset;
}

svg {
    width: 100%;
    height: auto;
    max-width: 100%;
}

.project-svg, .project-png {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
}

/* ----- LOADING SPINNER ----- */
.spinner-container {
    width: 100%;
    height: 100%;
    position: relative;
}

.spinner-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner {
    position: absolute;
    transform: translate(-50%, -50%);
    height: 56px;
    width: 56px;
    box-sizing: border-box;
    background: conic-gradient(
            from 90deg at 50% 50%,
            #C348B0  0%,
            #2036FF 100%
    );

    border-radius: 56px;
    animation: 1.5s rotate infinite linear;
}

.spinner::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 40px;
    width: 40px;
    background: #fff;
    border-radius: 48px;
}

.spinner::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 8px;
    width: 8px;
    background: #2036FF 100%;
    border-radius: 8px;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
/* ---- END -----*/

.nav-header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: end;
    justify-content: flex-end;
    z-index: 999;
    position: fixed;
    background: rgb(255, 255, 255, 0%);
    margin-top: 15px;
}

.nav-buttons-container {
    margin-right: 2.5rem;
}

.nav-buttons-wrapper{
    border-radius: 100px;
    background: rgb(220 220 220 / 44%);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(6px);
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 5px 0 5px;
}

.nav-btn {
    cursor: pointer;
    background: none;
    border: none;
    font-family: "Poppins", serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1rem;
    position: relative;
    justify-self: center;
    color: #2D2D2D;
    padding: 9px 14px;
}

.nav-btn-portfolio {
    cursor: pointer;
    background: none;
    border: none;
    font-family: "Poppins", serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1rem;
    position: relative;
    justify-self: center;
    color: #FFF;
    background: #000;
    border-radius: 100px;
    padding: 9px 14px;
}

.nav-btn.spaced {
    margin-right: 10px;
}

.nav-btn.active, .nav-btn:hover {
    color: #FFF;
    background: #000;
    border-radius: 100px;
    padding: 9px 14px;
}

.main-background {
    z-index: 0;
    height: fit-content;
}

.ornaments-container {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
}

.background-sprinkles {
    position: absolute;
    right: -23rem;
    top: -2rem;
    width: 55rem;
    height: auto;
}

.welcome-section {
    height: 40rem;
    width: 100vw;
    display: flex;
    align-items: center;
    padding-left: 12vw;
}

.welcome-wrapper {
    font-family: "Poppins", serif;
    font-style: normal;
    justify-content: center;
    align-items: center;
    width: 44rem;
    padding-top: 4rem;
    position: relative;
}

.name-surname-p {
    font-size: 2.5rem;
    font-weight: 400;
    font-family: "Zeyada", serif;
    color: #0B74D5;
    margin: 0;
    text-align: left;
}

.ux-ui-p {
    color: #000;
    line-height: normal;
    text-align: left;
    margin: -23px 0 0 0;
    font-size: 6rem;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.96px;
}

.self-description {
    color: #2D2D2D;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.061px;
    margin: 0;
}

.self-description.hi {
    font-size: 1.2rem;
}

.portfolio-header-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 7.5rem;
}

.portfolio-header {
    cursor: pointer;
    font-family: "Poppins", serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.portfolio-span {
    background: #000;
    color: #FFF;
    border-radius: 100px;
    padding: 10px 22px
}

.down-anim-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(0);
    animation: moveUpDown 0.6s ease-in-out infinite alternate;
}

.down-arrow {
    margin-top: 7px;
    width: 40%;
}

@keyframes moveUpDown {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(15px);
    }
}

.portfolio-content-section {
    display: flex;
    justify-content: center;
}

.portfolio-projects-grid {
    display: grid;
    grid-template-rows: 1fr;
    row-gap: 150px;
}

.portfolio-project {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.project-photo-wrapper {
    grid-column: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.photo-container {
    position: relative;
    cursor: pointer;
    display: flex;
}

.project-label {
    position: absolute;
    left: -18px;
    top: -21px;
    background: #000;
    padding: 9px 14px;
    color: #FFF;
    border-radius: 10px;
    font-weight: 400;
}

.no-pointer {
    cursor: unset;
}

.project-photo-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Poppins", serif;
    font-style: normal;
    line-height: normal;
    font-weight: 600;
    font-size: 1.2rem;
    color: #FFF;
    border-radius: 20px;
}

.photo-container:hover .project-photo-overlay {
    opacity: 1;
}

.portfolio-project-photo {
    background-size: cover;
    background-position: center;
    max-width: 27.4rem;
    max-height: 18rem;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.portfolio-project-text {
    grid-column: 2;
    font-family: "Poppins", serif;
    font-style: normal;
    line-height: normal;
    font-weight: 600;
    padding-right: 2vw;
}

.project-name {
    color: #000;
    font-size: 2rem;
    margin-top: 19px;
    margin-bottom: 4px;
    cursor: pointer;
}

.project-name.no-pointer {
    cursor: unset;
}

.project-subtitle {
    color: #D9767B;
    font-size: 1.25rem;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 16px;
}

.project-description {
    color: #2D2D2D;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 30px;
    max-width: 35rem;
}

.figma-link {
    cursor: pointer;
    color: #0b74D5;
}

.figma-link:hover {
    text-decoration: underline #0b74D5;
}

.see-more-wrapper {
    display: flex;
    align-items: center;
}

.see-more-button {
    cursor: pointer;
    background: none;
    border: 0;
    color: #000;
    text-align: center;
    font-family: "Poppins", serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.042px;
    display: flex;
    align-items: center;
    padding: 0;
}

.arrow-icon-wrapper {
    margin-top: 5px;
    transition: transform 0.3s ease;
}

.see-more-button:hover .arrow-icon-wrapper {
    transform: translateX(15px);
}

.experience-section {
    margin-top: 8rem;
    margin-bottom: 10.2rem;
    width: 100%;
    height: 45rem;
    background: rgba(78, 85, 201, 0.05);
    font-family: "Poppins", serif;
    font-style: normal;
    line-height: normal;
    display: grid;
    grid-template-rows: max-content max-content max-content;
}

.experience-p {
    padding-top: 9.6rem;
    font-size: 2rem;
    font-weight: 600;
    color: #000;
    text-align: center;
    margin-bottom: 0.5rem;
}

.experience-description {
    justify-self: center;
    color: #000;
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 56rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.experience-brands-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10rem;
    margin-top: 1rem;
}

.experience-brand {
    background-size: cover;
    background-position: center;
    max-width: 14rem;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.brand-wrapper {
    display: flex;
    align-items: center;
}

.brand-wrapper.a {
    grid-column: 1;
    justify-content: flex-end;
    padding-left: 1.5rem;
}

.brand-wrapper.b {
    grid-column: 2;
    justify-content: flex-start;
    padding-right: 1.5rem;
}

.contact-section {
    display: grid;
    justify-content: center;
    align-items: center;
    font-family: "Poppins", serif;
    font-style: normal;
    line-height: normal;
    padding-bottom: 12.7rem;
    position: relative;
}

.contact-p {
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    color: #000;
    margin-bottom: 5px;
}

.contact-msg-p {
    color: #000;
    justify-self: center;
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 40.3rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.contact-buttons-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 4vw;
    margin-top: 1rem;
}

.contact-button-frame {
    display: grid;
    align-items: center;
    justify-self: center;
    padding: 1rem;
    cursor: pointer;
    width: 100%;
}

.contact-button-frame.phone {
    grid-column: 1;
}

.contact-button-frame.email {
    grid-column: 2;
}

.contact-button-frame.linkedin {
    grid-column: 3;
}

.contact-icon {
    padding: 5px;
    justify-self: center;
}

.contact-icon-text {
    padding-top: 0.9rem;
    font-size: 1rem;
    font-weight: 600;
    justify-self: center;
    padding-left: 1rem;
    padding-right: 1rem;
}

footer {
    position: absolute;
    bottom: 0;
    color: #757575;
    font-family: "Poppins", serif;
    font-size: 0.85rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.ekosort-grid {
    display: grid;
    grid-template-rows: 2.5rem 1fr;
    height: 100%;
}

.ekosort-navigation-container {
    width: 100%;
    position: fixed;
    grid-row: 1;
    height: 2.5rem;
    background: #FFF;
}

.ekosort-navigation {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 100%;
}

.nav-tab {
    cursor: pointer;
    font-family: "Poppins", serif;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-tab:hover, .nav-tab.active {
    background-color: #F8F8F8;
    color: #0b74D5;
}

.nav-tab.hi-fi {
    grid-column: 1;
}

.nav-tab.hi-fi:hover, .nav-tab.hi-fi.active {
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
}

.nav-tab.research {
    grid-column: 2;
}

.nav-tab.research:hover, .nav-tab.research.active {
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
}

.nav-tab.ideate {
    grid-column: 3;
}

.nav-tab.ideate:hover, .nav-tab.ideate.active {
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
}

.project-part {
    grid-row: 2;
}

.certificates-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.certificates-grid {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 15% 1fr;
    width: 100%;
    justify-content: center;
    column-gap: 1rem;
}

.certificate {
    display: flex;
    align-items: center;
}

.certificate.a {
    grid-column: 1;
    justify-content: flex-end;
}

.certificate.b {
    grid-column: 2;
    justify-content: center;
}

.certificate.c {
    grid-column: 3;
    justify-content: flex-start;
}

.certificate-image {
    height: auto;
    cursor: pointer;
    transition: width 0.3s ease, height 0.3s ease;
}

.certificate-image.vertical {
    width: 8rem;
}

.certificate-image.horizontal {
    width: 16rem;
}

.certificate-image.vertical:hover {
    width: 8.5rem;
}

.certificate-image.horizontal:hover {
    width: 16.5rem;
}

.certificate-dialog.vertical {
    max-width: 100%;
    width: auto;
    height: 100vh;
}

.certificate-dialog.horizontal {
    max-width: 100%;
    width: auto;
    height: 100vh;
}

.dialog-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100vw;
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 9000;
}

.dialog-overlay.show {
    display: flex;
}

@media (max-width: 1000px) {

    .nav-header {
        justify-content: center;
    }

    .nav-buttons-container {
        margin: 0;
    }

    .welcome-section {
        justify-content: center;
        padding: 0;
    }

    .welcome-wrapper {
        width: 35rem;
    }

    .background-sprinkles {
        right: -19rem;
        top: -4rem;
        width: 55rem;
    }

    .self-description {
        font-size: 0.9rem;
    }

    .self-description.hi {
        font-size: 0.95rem;
    }

    .name-surname-p {
        font-size: 1.8rem;
    }

    .ux-ui-p {
        font-size: 4rem;
    }

    .portfolio-projects-grid {
        row-gap: 80px;
    }

    .portfolio-project {
        grid-template-columns: 1fr;
    }

    .portfolio-project-text {
        grid-column: 1 !important;
        max-width: 27.4rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        margin-top: 1rem;
    }

    .project-photo-wrapper {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .experience-brands-grid {
        column-gap: 2.3rem !important;
    }

    .certificates-grid {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width: 700px) {

    .nav-buttons-wrapper {
        position: relative;
        z-index: 9999;
    }

    .nav-buttons-container {
        position: relative;
        z-index: 9999;
    }

    .welcome-wrapper {
        padding: 0 2rem;
        z-index: 10;
    }

    .ux-ui-p {
        position: relative;
        z-index: 9999;
    }

    .self-description {
        position: relative;
        z-index: 9999;
    }

    .background-sprinkles {
        top: -8rem;
        right: -18rem;
    }

    .experience-description {
        text-align: center;
    }

    .contact-msg-p {
        text-align: center;
    }

    .contact-buttons-grid {
        grid-template-columns: 1fr;
    }

    .contact-button-frame.phone {
        grid-column: 1;
    }

    .contact-button-frame.email {
        grid-column: 1;
    }

    .contact-button-frame.linkedin {
        grid-column: 1;
    }

    .certificate-image {
        width: 11rem;
    }

    .certificate-dialog.horizontal {
        width: 100vw;
        height: auto;
        max-height: 100%;
    }

    .certificate-image.vertical {
        width: 15vw;
    }

    .certificate-image.horizontal {
        width: 30vw;
    }

    .certificate-image.vertical:hover {
        width: 15vw;
    }

    .certificate-image.horizontal:hover {
        width: 30vw;
    }
}

@media (max-width: 516px) {

    .name-surname-p {
        font-size: 1.7rem;
    }

    .ux-ui-p {
        font-size: 3.1rem;
        margin: -15px 0 0 0;
    }

    .portfolio-span {
        font-size: 1.3rem;
    }

    .contact-p {
        font-size: 1.5rem;
    }

    .contact-msg-p {
        font-size: 0.85rem;
    }

    .contact-icon {
        width: 3rem;
        height: 3rem;
    }

    .contact-icon-text {
        font-size: 0.85rem;
    }

    .experience-p {
        font-size: 1.5rem;
    }

    .experience-description {
        font-size: 0.85rem;
    }

    .experience-brand {
        width: 80%;
    }

}
@media (max-width: 450px) {

    .nav-btn {
        font-size: 3vw;
    }

    .nav-buttons-wrapper {
        height: 10vw;
    }

    .background-sprinkles {
        right: -20rem;
    }

    .project-label {
        font-size: 0.9rem;
    }

    .certificate-dialog.vertical {
        max-width: 100vw;
        height: auto;
        max-height: 100%;
    }

    .certificate-image {
        max-width: 100vw;
        height: auto;
    }
}

@media (max-width: 410px) {

    .ux-ui-p {
        font-size: 2.8rem;
    }
}

@media (max-width: 375px) {
    .ux-ui-p {
        font-size: 2.5rem;
    }
}


@media (hover: none) and (pointer: coarse) {
    .nav-underline {
        display: none;
    }
}